<template>
  <component
    :is="label ? 'label' : 'span'"
    class="osk-field osk-radio"
    :class="{'osk-radio_justifyBetween': justifyBetween}"
  >
    <span
      v-if="label && labelLeft"
      :class="{
        'osk-radio__label': true,
        'osk-radio__label_left': labelLeft,
      }"
    >
      {{ label }}
    </span>

    <input
      :value="valueComp"
      type="radio"
      :disabled="disabled ?? false"
      :checked="checked"
      :class="{
        'osk-radio__input': true,
      }"
      @change="handleChange(value ?? true)"
    >
    <UikitIcon
      v-if="checked"
      name="RadioButtonON"
      class="osk-radio__icon"
      :color="color"
      :size="iconSize"
    />

    <UikitIcon
      v-else
      name="RadioButtonOFF"
      class="osk-radio__icon"
      :color="color"
      :size="iconSize"
    />

    <span
      v-if="label && !labelLeft"
      class="osk-radio__label"
    >
      {{ label }}
    </span>
  </component>
</template>

<script setup lang="ts">
import { RuleExpression, useField } from 'vee-validate';

const props = defineProps<{
  modelValue?: any,
  name: string,
  value: any,
  label?: string,
  labelLeft?: boolean,
  justifyBetween?: boolean,
  disabled?: boolean,
  rules?: RuleExpression<string | number | number[] | boolean | null>,
  color?: 'white'
  iconSize?: 's' | 'm' | 'l' | 'xl' | 'auto'
}>();

const { value: valueComp, checked, handleChange } = useField<any>(toRef(props, 'name'), props.rules, {
  type: 'radio',
  initialValue: props.modelValue ?? undefined,
  checkedValue: props.value,
  syncVModel: true,
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &_justifyBetween {
    justify-content: space-between;
  }

  &__input {
    display: none;
  }

  &__icon {
    cursor: pointer;
    flex-shrink: 0;
  }

  &__label {
    margin-left: $indent-compact;
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);

    &_left {
      @include font-style($font-size-body, $line-height-body, $font-weight-medium);
      margin-left: 0;
    }
  }
}
</style>
